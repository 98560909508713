import { ChatCompletionRequest } from "../types";

export async function getStreamingChatCompletion(
  chatCompletionRequest: ChatCompletionRequest
): Promise<ReadableStream<Uint8Array> | null> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/chat/completion`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.ELOQUENCE_API_KEY || "",
      },
      method: "POST",
      body: JSON.stringify(chatCompletionRequest),
    })
      .then((response) => {
        return response.body;
      })
      .then((body: ReadableStream<Uint8Array> | null) => {
        resolve(body);
      });
  });
}
