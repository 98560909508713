import React from "react";
import "./style.css";
import SidePanel from "../../../components/panel";
import Reference from "./Reference";

export type ReferencesPanelProps = {
  references: any[];
  visible: boolean;
  onClose: () => void;
};

const ReferencesPanel: React.FC<ReferencesPanelProps> = ({
  references,
  visible,
  onClose,
}) => {
  return (
    <>
      <SidePanel isOpen={visible} onClose={onClose}>
        <h4 style={{ textAlign: "center" }}>References</h4>
        <div className="reference-container">
          {references.map((reference) => {
            return <Reference url={reference.url} status={reference.status} />;
          })}
        </div>
      </SidePanel>
    </>
  );
};

export default ReferencesPanel;
