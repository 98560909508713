import React, { ReactNode } from "react";
import "./style.css";
import { SlClose } from "react-icons/sl";

interface SidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const SidePanel: React.FC<SidePanelProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      <div
        className={`panel-overlay ${isOpen ? "open" : ""}`}
        onClick={onClose}
      />
      <div className={`panel-content ${isOpen ? "open" : ""}`}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SlClose
            size={15}
            color="white"
            style={{
              margin: "5px 5px 0px 0px",
              alignSelf: "end",
            }}
            onClick={onClose}
          />
          <div className="panel-inner-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
