import React from "react";
import "./style.css";
import { SlCheck, SlExclamation, SlClose } from "react-icons/sl";

export type ReferenceProps = {
  url: string;
  status: string;
};

function StatusIcon({ status }: { status: string }) {
  if (status === "success") {
    return <SlCheck color="#00ff00" style={{ marginRight: "10px" }} />;
  } else if (status === "pending") {
    return <SlExclamation color="#ffff00" style={{ marginRight: "10px" }} />;
  } else if (status === "failed") {
    return <SlClose color="#ff0000" style={{ marginRight: "10px" }} />;
  } else {
    return <></>;
  }
}

export default function Reference({ url, status }: ReferenceProps) {
  return (
    <div className={`reference-card reference-${status}`}>
      <a href={url} target="_blank" rel="noreferrer">
        <StatusIcon status={status} />
        {url}
      </a>
    </div>
  );
}
